import {VTreeview,VTreeviewItem,VTreeviewGroup} from 'vuetify/labs/VTreeview'

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"ssr":true}')
  
  
  options.components = {VTreeview,VTreeviewItem,VTreeviewGroup}
  
  return options
}

