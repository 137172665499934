export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              background: "#ffffff", // for background color
              surface: "#ffffff", // for card color
              coffee: "#fff3e0", // fully custom color, made for Royal layout
              MainBannerColor: "#ffffff",
              rating: "#f4c74d",
              button: "#3a96aa",
              blockTitleBackground: "#dde7e9",
              breadcrumbs: "#0076a3",
              cardTitle: "#dde7e9",
              H1bg: "#f7e7cf",
              linkColor: "#0000ee",
              jackpot: "#3a96aa",
              buttonTextColor: "#ffffff",
              buttonBackground: "#3a96aa",
              iconColor: "#3a96aa",
            },
          },
          dark: {},
        },
      },
    });
  });
});
