<template>
  <ClientOnly><NuxtLoadingIndicator color="#FF6C9B" :throttle="0" :height="3" /></ClientOnly>
  <NuxtLayout> </NuxtLayout>
</template>

<script setup>
// <VitePwaManifest />
import { pageQuery } from "@/apollo/queries/PageQuery.gql";
import { routeFix } from "@/lib/tools";

const router = useRouter();
const route = useRoute();
// const headers = useRequestHeaders();
const url = useRequestURL();

if (process.dev) pageQuery.cache = "no-cache";

const runtimeConfig = useRuntimeConfig();

let hostname = process.dev ? runtimeConfig.public.hostname || url.host : url.host;
if (typeof window !== "undefined" && window.hostBase) hostname = window.hostBase;

// const layout = runtimeConfig.public.layout;
// console.log("url.host", url.host, "hostname", hostname);
const { data } = await useAsyncQuery(pageQuery, {
  hostname,
  uri: routeFix(route.path),
});

if (_.get(unref(data), ["pageQuery", "page", "uri"], {}) === "404.html") {
  const event = useRequestEvent();
  setResponseStatus(event, 404);
}

const page = ref(injectA(data));
page.value.removeRegion = region => {
  if (page?.value?.blocks && page.value.blocks[unref(region)]) delete page.value.blocks[unref(region)];
};

provide("page", readonly(page));
// console.log("page.hostname", unref(page).hostname);

const head = computed(() => _.get(unref(page), "meta"));
useHead(head);

// router.beforeEach((to, from) => {
//   if (!_.isEqual(to, from)) {
//     useAsyncQuery(pageQuery, { hostname, uri: routeFix(to.path) }).then(res => {
//       page.value = injectA(res.data);
//       if (window) window.scrollTo(0, 0);
//     });
//   }
// });

router.afterEach((to, _from) => {
  // console.log("afterEach", routeFix(to.path), _from, page.value.uri);
  // if (!_.isEqual(to, from)) {
  if (routeFix(to.path) !== page.value.uri) {
    // console.log("afterEach!", window.document.location.href);

    useAsyncQuery(pageQuery, { hostname, uri: routeFix(to.path) }).then(res => {
      page.value = injectA(res.data);
      if (window) window.scrollTo(0, 0);
    });
  }
});
</script>
